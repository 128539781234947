.text {
  font-size: 1.3rem;
  line-height: 2.1rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text_small {
  font-size: 1rem;
  line-height: 1.9rem;
  color: rgba(0, 0, 0, 0.65);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main {
  margin-top: 1.6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0.6rem;
  border: 1px solid #f0f0f0;
}

.table {
  border-top: 1px solid #f0f0f0;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  background-color: #fafafa;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  gap: 0.2rem;
}

.header_top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.header_top_total {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.header_top_action {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  flex: 1;
}

.header_action_container {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.multiple_select_action_container {
  margin-top: 0.5rem;
  width: 100%;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  gap: 1rem;
}

.bottom {
  padding: 1.6rem 1rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.text_title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 1.6rem;
  font-weight: 600;
}

.text_desc {
  color: rgba(0, 0, 0, 0.45);
  font-size: 1.1rem;
}

.event_row {
  background-color: #f8f8f8;
}

.tables_container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 1.6rem;
}

.tables_item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tables_header {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* padding: 1.6rem; */
  gap: 0.2rem;
}

.tables_header_top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}