.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0rem 1.6rem;
  gap: 1rem;
}

.text {
  font-size: 1.3rem;
  line-height: 2.1rem;
  color: rgba(0, 0, 0, 0.65);
  /* white-space: nowrap; */
  /* overflow: hidden;
  text-overflow: ellipsis; */
}

.tag {
  font-size: 1.3rem;
  line-height: 2.1rem;
  color: rgba(0, 0, 0, 0.65);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button_icon_keywords {
  width: 28px;
  height: 28px;
  cursor: pointer;
}